import React, { useState } from "react";
import "../../../assets/css/Style.css";
import { useTranslation } from "react-i18next";

export default function Language(props) {
  let { i18n } = useTranslation();
  let content = props.content;
  let setLanguageValue = props.setLanguageValue;
  let [languageName, setLanguageName] = useState("عربي");
  let [show, setShow] = useState(false);

  // Function For Change Language
  let changeLanguage = (i) => {
    setLanguageName(i.name);
    i18n.changeLanguage(i.value);
    setLanguageValue(i.value);
  };
  let Select = content.map((item) => {
    return (
      <span
        key={item.id}
        onClick={() => {
          changeLanguage(item);
          setShow(!show);
        }}
      >
        {item.name}
      </span>
    );
  });
  return (
    <div className="Language">
      <div className="language-box">
        <div className="language-title" onClick={() => setShow(!show)}>
          <div>
            <span>{languageName}</span>
          </div>
          <div></div>
        </div>
        {show ? <div className="language-list">{Select}</div> : null}
      </div>
    </div>
  );
}
