import React from "react";
import "../../assets/css/Style.css";
// Lottie
import Lottie from "lottie-react";
import animationData from "../../assets/Lotties/real-estate-loading.json";

export default function REL() {
  return (
    <div className="REL">
      <div className="svg">
        <Lottie animationData={animationData} autoPlay loop />
      </div>
    </div>
  );
}
