import React from "react";
import '../../../assets/css/Style.css';
import SvgLocation from "../../../assets/icons/SvgLocation";

export default function Location({ content, language }){
    // Render
    let locations = content.locations;
    let view = () => { 
        return locations
        .filter((e) => language === e.locale)
        .map((item, index) => {
        return(
            <div key={index}>
                <SvgLocation />
                <div key={index}>{item.city} : {item.area}</div>
            </div>
        )
    })
}
    return(
        <div className="location">
            <h3>{content.title}</h3>
            <div className="box">{view()}</div>
        </div>
    )
}