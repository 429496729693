import React from "react";

const SvgProfile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M465.44 12.62c-68.63 11.53-127.07 55.17-158.02 117.26-59.59 119.57 4.81 264.32 133.8 301.62 112.84 32.87 232.41-33.64 265.29-147.44 32.1-110.73-30.18-227.8-140.53-263.75-30.76-10-69.39-13.07-100.54-7.69zM430.84 542.23c-155.71 12.3-265.48 61.9-329.11 148.79-20.19 27.68-37.87 67.28-44.6 99.96-4.23 19.8-4.23 87.27-.19 104.77 7.88 32.87 33.83 65.36 64.59 80.55 29.99 14.8 3.84 14.03 384.86 13.46l340.64-.58 12.5-4.42c20.38-7.3 36.53-17.5 51.13-32.1 11.15-11.15 15.57-17.49 22.49-31.91 4.81-10 9.61-22.69 10.77-28.26 3.27-15.96 2.69-84.2-.96-101.5-15-73.44-64.78-139.57-137.64-183.2-52.68-31.53-120.73-52.29-206.27-62.67-29.03-3.66-136.3-5.39-168.21-2.89z" />
  </svg>
);

export default SvgProfile;
