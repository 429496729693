import React from "react";
import { useNavigate } from "react-router-dom";
import '../../../assets/css/Style.css';

export default function Logo({content}){
    let navigate = useNavigate();
    return(
        <div className="Logo" onClick={() => navigate('/')}>
            <h1>{content}</h1>
        </div>
    )
}