import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export default function SharedLayout(props){
    return (
        <>
        <Header setLanguageValue={props.setLanguageValue} language={props.language} pagesAPI={props.pagesAPI} />
        <section><Outlet /></section>
        <Footer language={props.language} contactAPI={props.contactAPI}  />
        </>
    )
}

