import React, { useState } from "react";
import "../../assets/css/Style.css";
import SvgX from "../../assets/icons/SvgX";

export default function SignIn({ show, setShow, isActive, setIsActive, signin, user, language }) {
  let [dataUser, setDataUser] = useState({first_name: '', last_name: '', phone: '', pass: ''})
  
  let handlerToggle = () => {
    setShow(!show);
    setIsActive('');
  }
  // Handler Sign In 
  let handlerSubmit = e => {
    e.preventDefault();
    signin(dataUser)
  }
  return (
    <div className="SignIn" style={{top: isActive === 'SignIn' ? '50%' : '-50%'}}>
        <h1>{language === 'ar' ? 'أنشاء حساب' : 'Sign In'}</h1>
        <form onSubmit={handlerSubmit}>
          <div>
            <input type='text' placeholder={language === 'ar' ? "الاسم الاول" : 'First Name'} required onChange={e => setDataUser({...dataUser, first_name: e.target.value})} value={dataUser.first_name} /> 
            <input type='text' placeholder={language === 'ar' ? "الاسم الاخير" : 'Last Name'}  required onChange={e => setDataUser({...dataUser, last_name: e.target.value})} value={dataUser.last_name} /> 
            <input type='phone' placeholder={language === 'ar' ? "رقم الهاتف" : 'Phone Number'}  required onChange={e => setDataUser({...dataUser, phone: e.target.value})} value={dataUser.phone} /> 
            <input type='password' placeholder={language === 'ar' ? "كلمة السر" : 'Password'}  required onChange={e => setDataUser({...dataUser, pass: e.target.value})} value={dataUser.pass} /> 
          </div>
          <div className="error">{user ? null : `${language === 'ar' ? 'رقم الهاتف موجود مسبقاََ' : 'There Is An Error'}`}</div>
          <button>{language === 'ar' ? 'أنشاء' : 'Sign In'}</button>
        </form>
        <div className="acount">
            <div>{language === 'ar' ? 'لديك حساب ؟' : 'Do You Have Account ?'}</div>
            <a href="#" onClick={() => setIsActive('Login')}>{language === 'ar' ? 'تسجيل دخول' : 'Go Login'}</a>
        </div>
        <div className="X" onClick={() => handlerToggle()}>
          <SvgX />
        </div>
    </div>
  );
}
