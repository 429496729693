import React from "react";
import "../../../assets/css/Style.css";

export default function UserProfile(props) {
  let userData = props.userData;
  return (
    <div className="UserProfile">
      <div>{userData.name}</div>
    </div>
  );
}
