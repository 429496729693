import React, { useState, useEffect } from "react";
import "../../assets/css/Style.css";
import { useTranslation } from "react-i18next";
// Components
import Logo from "./Logo/Logo";
import LM from "./Menu/Little-Menu/LM";
import Menu from "./Menu/Menu";
import Profile from "./Profile/Profile";
import Search from "./Search/Search";
import Login from "../Login/Login";
import SignIn from "../SignIn/SignIn";
import Language from "./Language/Language";
import UserProfile from "./Profile/UserProfile";
import axios from "axios";

function Header(props) {
  // For Language
  let { t } = useTranslation();
  let language = props.language;

  let [show, setShow] = useState(false);
  let [isActive, setIsActive] = useState("");
  let [isScrolling, setIsScrolling] = useState(false);
  let [user, setUser] = useState(true);
  let session = sessionStorage.getItem("phone");
  let data = {
    logo: {
      content: t("myWeb"),
    },
    itemOfMenu: [
      { item: "الرئيسية", linkTo: "/", locale: 'ar' },
      { item: "Home", linkTo: "/", locale: 'en' },
      { item: "للأيجار", linkTo: "/Rent", locale: 'ar' },
      { item: "For Rent", linkTo: "/Rent", locale: 'en' },
      { item: "للبيع", linkTo: "/Sale", locale: 'ar' },
      { item: "For Sale", linkTo: "/Sale", locale: 'en' },
    ],
    dropList: [
      { id: 1, name: "عربي", value: "ar" },
      { id: 2, name: "English", value: "en" },
    ],
    itemOfLM: [
      { item: "الرئيسية", linkTo: "/" },
      { item: "للأيجار", linkTo: "/Rent" },
      { item: "للبيع", linkTo: "/Sale" },
      { item: "اتصل بنا", linkTo: "#contcat-us" },
    ],
    Login: {
      title: "تسجيل الدخول",
      input: [
        { type: "phone", txt: "رقم الهاتف" },
        { type: "password", txt: "كلمة السر" },
      ],
    },
    SignIn: {
      title: "أنشاء حساب",
      input: [
        { type: "text", txt: "الاسم الاول" },
        { type: "text", txt: "الاسم الاخير" },
        { type: "phone", txt: "رقم الهاتف" },
        { type: "password", txt: "كلمة السر" },
      ],
    },
    users: [
      {
        id: 1,
        first_name: "kamal",
        last_name: "lutf",
        phone: "771440903",
        pass: "kamal",
      },
    ],
  };
  // ---------- Handler Functions ----------
  let handlerShow = () => {
    setShow(!show);
    setIsActive("Login");
  };
  const changeBackground = () => {
    if (window.scrollY >= 250) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  // ---------- Handler Check Login ----------
  let handlerLogin = (check) => {
    axios
      .post("https://www.properties-api.mediamaxtv.com/api/v1/loginuser", {
        phone: check.phone,
        password: check.pass,
      })
      .then((res) => {
        if (res.data.user && res.data.user.type !== "admin") {
          let data = { ...res.data.user };
          // Session
          sessionStorage.setItem("userData", JSON.stringify(res.data.user));
          setUser(true);
          setShow(!show);
          setIsActive("");
        } else if (res.data.user.type === "admin") {
          setUser(false);
        }
      })
      .catch((e) => (e ? setUser(false) : null));
  };

  let clearSession = () => {
    sessionStorage.clear();
    window.location.reload();
  };
  // ---------- Handler Sign In ----------
  let handlerSignIn = (value) => {
    axios
      .post("https://www.properties-api.mediamaxtv.com/api/v1/register", {
        name: value.first_name + value.last_name,
        phone: value.phone,
        password: value.pass,
      })
      .then((res) => {
        sessionStorage.setItem("userData", JSON.stringify(res.data.data.user));
        setUser(true);
        setShow(!show);
        setIsActive("");
      })
      .catch((e) => {
        if (e) {
          setUser(false)
          console.log(e)
        }
      })
  };
  // ---------- User Data ----------
  let handlerProfile = () => {
    if (sessionStorage.getItem("userData")) {
      let userData = sessionStorage.getItem("userData");
      return (
        <div>
          <UserProfile userData={JSON.parse(userData)} />
        </div>
      );
    } else {
      return (
        <div onClick={() => handlerShow()}>
          <Profile />
        </div>
      );
    }
  };
  return (
    <div className={`Header ${isScrolling ? "Header-active" : null}`} style={{direction: language === 'ar' ? 'rtl' : 'ltr'}}>
      <div className="container">
        <div className="first-element">
          <Logo content={data.logo.content} />
          <Menu Menus={data.itemOfMenu} menuAPI={props.pagesAPI} language={language} />
        </div>
        <div className="last-element">
          <div>
            <Language
              content={data.dropList}
              setLanguageValue={props.setLanguageValue}
            />
          </div>
          <div>
            <Search />
          </div>
          {/* Handler Profile */}
          {handlerProfile()}
          <div className="little-menu">
            <LM
              content={data.itemOfMenu}
              showPopUp={show}
              setShowPopUp={setShow}
              isActive={isActive}
              setIsActive={setIsActive}
              session={session}
              clear={clearSession}
              menuAPI={props.pagesAPI} 
              language={language}
            />
          </div>
        </div>
        <div style={{ position: "absolute" }}>
          <Login
            content={data.Login}
            show={show}
            setShow={setShow}
            isActive={isActive}
            setIsActive={setIsActive}
            check={handlerLogin}
            user={user}
            language={language}
          />
          <SignIn
            content={data.SignIn}
            show={show}
            setShow={setShow}
            isActive={isActive}
            setIsActive={setIsActive}
            signin={handlerSignIn}
            user={user}
            language={language}
          />
          <div
            className="opacity"
            style={{
              opacity: show ? "0.5" : "0",
              zIndex: show ? "2" : "-1",
              position: show ? "fixed" : "relative",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
