import React from "react";
import '../../assets/css/Style.css';
import { useTranslation } from "react-i18next";
// Components
import Logo from "../Header/Logo/Logo";
import SvgTelegram from "../../assets/icons/SvgTelegram";
import SM from "./Social-Media/SM";
// Icon
import SvgFacebook from "../../assets/icons/SvgFacebook";
import SvgInstagram from "../../assets/icons/SvgInstagram";
import SvgTwitter from "../../assets/icons/SvgTwitter";
import IL from "./Important-Link/IL";
import CU from "./Contact-Us/CU";
import Location from "./Location/Location";

export default function Footer(props){
    let language = props.language;

    let {t} = useTranslation();

    let data = {
        logo: t('myWeb'),
        social_media: [
            {name: 'telegram', src: SvgTelegram},
            {name: 'facebook', src: SvgFacebook},
            {name: 'instagram', src: SvgInstagram},
            {name: 'twitter', src: SvgTwitter},
        ],
        IL: {
            title: t('ILTitle'),
            links: [
                {name: 'بحث عن عقار', to: '/search', locale: 'ar'},
                {name: 'Search', to: '/search', locale: 'en'},
                {name: 'من نحن', to: '/about-us', locale: 'ar'},
                {name: 'About Us', to: '/about-us', locale: 'en'},
                {name: 'تواصل معنا', to: '#contact-us', locale: 'ar'},
                {name: 'Contact Us', to: '#contact-us', locale: 'en'},
                {name:'سياسة الخصوصية', to: '/privacy-policy', locale: 'ar'},
                {name:'Privacy Policy', to: '/privacy-policy', locale: 'en'},
            ],
        },
        CU: {
            title: t('CUTitle'),
            with: [
                {name: 'Email', view: 'zzz@zzz.com'},
                {name: 'Phone', view: '771440903'}
            ]
        },
        Location: {
            title: t('LocationTitle'),
            locations: [
                {city: 'صنعاء', area: 'حدة - المدينة - خلف المعلم', locale: 'ar'},
                {city: "Sana'a", area: 'Haddi - Almadeni - Behind Almalm', locale: 'en'},
            ]
        }
    }
    return(
        <div className="Footer" style={{direction: language === 'ar' ? 'rtl' : 'ltr'}}>
            <div className="container">
                <div className="logo">
                    <Logo content={data.logo} />
                    <div className="social-media">
                        <SM content={data.social_media} contactAPI={props.contactAPI} />
                    </div>
                </div>
                <div className="site-directory">
                    <IL content={data.IL} language={language} />
                </div>
                <div className="Contact-Us">
                    <CU content={data.CU} />
                </div>
                <div className="Location">
                    <Location content={data.Location} language={language} />
                </div>
            </div>
        </div>
    )
}