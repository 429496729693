import React from "react";
import { Link } from "react-router-dom";
import '../../../assets/css/Style.css';

export default function IL({ content, language }){
    // Render
    let links = content.links;
    let box = () => { 
        return links
        .filter((e) => language === e.locale)
        .map((item, index) => {
        return(
            <Link to={item.to} key={index}>{item.name}</Link>
        )
    })
}
    return(
        <div className="IL">
            <h3>{content.title}</h3>
            <div className="links">{box()}</div>
        </div>
    )
}