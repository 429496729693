import React from "react";
import "../../../assets/css/Style.css";
import SvgProfile from '../../../assets/icons/SvgProfile';

export default function Profile() {
  return (
    <div className="Profile">
      <SvgProfile />
    </div>
  );
}
