import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_ar from './translations/ar.json';
import common_en from './translations/en.json';

let resources = {
    ar: {
        translation:{
            myWeb: 'عقاراتي',
            backgroundTitle: 'عقاراتي في اي مكان',
            SFRTitle: 'البحث عن عقار',
            SOSTitle: 'عقارات مميزة',
            NATitle: 'المضافة حديثاً',
            OCOTitle: 'اراء عملائنا',
            ILTitle: 'دليل الموقع',
            CUTitle: 'تواصل معنا عبر',
            LocationTitle: 'موقعنا'
        }
    },
    en: {
        translation:{
            myWeb: 'myWeb',
            backgroundTitle: 'My Properties Is In Any Where',
            SFRTitle: 'Search For A Real Estate',
            SOSTitle: 'Featured Properties',
            NATitle: 'Newly Added',
            OCOTitle: "Our customers' opinions",
            ILTitle: 'Important Links',
            CUTitle: 'Contact Us',
            LocationTitle: 'Location'
        }
    }
}
i18n
.use(initReactI18next)
.init({
    resources,
    lng: 'ar',
});

export default i18n;