import React from "react";
import "../../assets/css/Style.css";

export default function DP(props) {
    let content = props.content;

    let language = props.language;

    // Render 
    let Render = () => {
            return content
        .map((e) => {
            return(
                <>
                    <h1>{e.title}</h1>
                    <div className="box">
                        <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${e.img}`} alt={e.slug} />
                        <div className="txt">{e.disc}</div>
                    </div>
                </>
            )
        })
    }

    return(
        <div className="DP" style={{direction: language === 'ar' ? 'ltr' : 'rtl'}}>
            <div className="container">{Render()}</div>
        </div>
    )
}