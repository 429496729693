import React from "react";
import "../../../assets/css/Style.css";

export default function OCO(props) {
  let language = props.languageValue;

  // Get API 
  let reviewsAPI = props.reviewsAPI;

  // Render

  let Render = () => {
    return reviewsAPI
    .filter(r => language === 'ar' ? r.id_locale === 1 : r.id_locale === 2)
    .map(r => {
      return (
        <div className="card" key={r.id}>
          <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${r.img}`} alt={`${r.name} - profile`} />
          <div className="txt">
            <h3>{r.name}</h3>
            <p>{r.review}</p>
          </div>
        </div>
      );
    })
  }
  return (
    <div className="OCO">
      <h1>{props.content.title}</h1>
      <div className="box">{Render()}</div>
    </div>
  );
}
