import React from "react";
import '../../../assets/css/Style.css';

export default function CU({ content }){
    // Render
    let contact = content.with;
    let view = contact.map((item, index) => {
        return(
            <div key={index}>{item.name} : {item.view}</div>
        )
    })
    return(
        <div className="CU">
            <h3>{content.title}</h3>
            <div className="box">{view}</div>
        </div>
    )
}