import React from "react";
import "../../assets/css/Style.css";
import { useTranslation } from "react-i18next";
// Components
import BackgroundImage from "./BackgroundOfHome/BackgroundImage";
import BackgroundTitle from "./BackgroundOfHome/BackgroundTitle";
import SFR from "./Search-For-Realestate/SFR";
import SOS from "./Slides-Of-Special/SOS";
import NA from "./Newly-Added/NA";
import AU from "./About-Us/AU";
import OCO from "./Our-Customers-Opinions/OCO";
// AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function Home(props) {
  let {t} = useTranslation();

  let language = props.languageValue;
  // Get APIs
  let dataAPI = props.dataAPI;

  let data = {
    BOH: {
      background_image: require("../../assets/imgs/background-image.svg"),
      title: t('backgroundTitle'),
    },
    SFR: {
      title: t('SFRTitle'),
      type: [
        { text: "للبيع", class: "for sale", value: "للبيع" },
        { text: "للأيجار", class: "for rent", value: "أيجار" },
      ],
      for: [
        { name: "commercial", value: "تجاري" },
        { name: "residential", value: "سكني" },
        { name: "lands", value: "اراضي" },
      ],
    },
    SOS: {
      title: t('SOSTitle'),
      cards: [
        {
          id: 1,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Rent",
        },
        {
          id: 2,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Rent",
        },
        {
          id: 3,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Rent",
        },
        {
          id: 4,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "للبيع",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Sale",
        },
        {
          id: 5,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "للبيع",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Sale",
        },
        {
          id: 6,
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "للبيع",
          details: "ملزم عقد أيجار",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
          value: "Sale",
        },
      ],
    },
    NA: {
      title: t('NATitle'),
      cards: [
        {
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details:
            "الشاليه 3 غرف نوم 2 حمام دور ارضى معاه حديقه خاصه 43 متر مباشر على البحيره داون تاون وممشي تجاري فيه كل المطاعم والكافيهات والسينمات ",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
        },
        {
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details:
            "الشاليه 3 غرف نوم 2 حمام دور ارضى معاه حديقه خاصه 43 متر مباشر على البحيره داون تاون وممشي تجاري فيه كل المطاعم والكافيهات والسينمات ",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
        },
        {
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details:
            "الشاليه 3 غرف نوم 2 حمام دور ارضى معاه حديقه خاصه 43 متر مباشر على البحيره داون تاون وممشي تجاري فيه كل المطاعم والكافيهات والسينمات ",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
        },
        {
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details:
            "الشاليه 3 غرف نوم 2 حمام دور ارضى معاه حديقه خاصه 43 متر مباشر على البحيره داون تاون وممشي تجاري فيه كل المطاعم والكافيهات والسينمات ",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
        },
        {
          title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
          type: "أيجار",
          details:
            "الشاليه 3 غرف نوم 2 حمام دور ارضى معاه حديقه خاصه 43 متر مباشر على البحيره داون تاون وممشي تجاري فيه كل المطاعم والكافيهات والسينمات ",
          location: "صنعاء - حده - جوار المعلم",
          space: "5",
          img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
        },
      ],
    },
    AU: {
      title: "من نحن",
      img: require("../../assets/imgs/About-Us.svg"),
      txt:
        "عقار يا مصر منصة عقارية تمكنك من تداول العقار عبر موقعها الالكتروني وعبر تطبيق أبل و الاندرويد. يمنحك عقار يا مصر الوصول لهدفك بأسرع الطرق وأفضلها من خلال تصميم أجمل الواجهات التقنية وتوفير أحدث التقنيات بأفضل الخبرات العقارية واستخدام أفضل التقنيات البرمجية الحديثة ليخرج عقار يا مصر بأفضل حله. ويسعى قسم التطوير في المنصة إلى الاطلاع على اقتراحاتكم وتطوير العمل ليصل إلى مستوى تطلاعاتكم ونسعد بتواصلكم عن طريق بوابة التواصل في الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
    },
    OCO: {
      title: t('OCOTitle'),
      cards: [
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
        {
          name: "كمال الخولاني",
          message:
            "الموقع أو التطبيقات. لدى عقار يا مصر فريق متكامل لفرز العقارات ومتابعة السوق للحد من العروض الوهمية ولرفع مستوى ثقة العميل. تشرفنا بزيارتك.",
          img: require("../../assets/imgs/logo.jpg"),
        },
      ],
    },
  };
  
  return (
    <div className="Home" style={{direction: language === 'ar' ? 'ltr' : 'rtl'}}>
      <div className="background-of-home">
        <div className="container">
          <BackgroundImage content={data.BOH.background_image} />
          <BackgroundTitle content={data.BOH.title} />
        </div>
      </div>
      <div
        className="search-for-realestate"
        style={{ marginTop: "100px" }}
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div className="container">
          <SFR content={data.SFR} />
        </div>
      </div>
      <div className="slides-of-special">
        <div className="container">
          <SOS data={dataAPI} languageValue={props.languageValue} content={data.SOS} />
        </div>
      </div>
      <div className="newly-added">
        <div className="container">
          <NA content={data.NA} dataAPI={dataAPI} languageValue={props.languageValue} />
        </div>
      </div>
      <div id="about-us" className="about-us">
        <div className="container">
          <AU aboutAPI={props.aboutAPI} languageValue={props.languageValue} />
        </div>
      </div>
      <div className="our-customers-opinions">
        <div className="container">
          <OCO content={data.OCO} reviewsAPI={props.reviewsAPI} languageValue={props.languageValue} />
        </div>
      </div>
    </div>
  );
}
