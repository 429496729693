import React from "react";
import { useNavigate } from "react-router-dom";
import '../../../assets/css/Style.css';

const SvgSearch = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      d="M11.328 3a8.328 8.328 0 1 0 5.265 14.77l4.972 4.972a.833.833 0 1 0 1.178-1.178l-4.973-4.971A8.319 8.319 0 0 0 11.328 3Zm0 1.666a6.662 6.662 0 1 1-6.662 6.662 6.65 6.65 0 0 1 6.662-6.662Z"
      transform="translate(-3 -3)"
    />
  </svg>
)

export default function Search(){
  let navigate = useNavigate();
    return(
        <div className="Search" onClick={() => navigate('/search')}>
            <SvgSearch />
        </div>
    )
}