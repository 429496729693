import React, { useState } from "react";
import "../../../assets/css/Style.css";

export default function SFR({ content }) {
  let [type, setType] = useState(0);
  let types = content.type;
  let forWhat = content.for;

  // Render
  let renderTypes = types.map((item, index) => {
    return (
      <div
        key={index}
        className={item.class}
        onClick={() => setType(index)}
        style={{
          backgroundColor: type === index ? "var(--main-color)" : null,
          color: type === index ? "#FFF" : null,
        }}
      >
        {item.text}
      </div>
    );
  });
  let renderSelects = forWhat.map((item, index) => {
    return (
      <option key={index} value={item.name}>
        {item.value}
      </option>
    );
  });
  return (
    <div className="SFR">
      <h1>{content.title}</h1>
      <div className="type">{renderTypes}</div>
      <form>
        <div>
          <input type="text" placeholder="البحث في محافظة او مدينة" />
          <select>{renderSelects}</select>
        </div>
        <input type='submit' value='بحث' className="submit" />
      </form>
    </div>
  );
}
