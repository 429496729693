import React from "react";

const SvgX = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="#000"
    viewBox="0 0 32 32"
    {...props}
  >
    <path strokeWidth={4} d="m2 2 28 28M2 30 30 2" />
  </svg>
);

export default SvgX;
