import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../assets/css/Style.css";

export default function Menu(props) {
  let language = props.language;

  // Get APIs
  let menuAPI = props.menuAPI;

  // Render Static Menu
  let Menus = props.Menus;

  let view = () => { 
    return Menus
    .filter((m) => language === m.locale)
    .map((items, index) => {
    return (
      <li key={index}>
        <NavLink
          to={items.linkTo}
          style={({ isActive }) => {
            return { color: isActive ? "var(--main-color)" : "#FFF" };
          }}
        >
          {items.item}
        </NavLink>
      </li>
    );
  });
}

  // Render Menu From API 
  let Render = () => {
    return menuAPI
      .filter((m) => language === m.locale)
      .map((m) => {
        return (
          <li key={m.id}>
            <NavLink
              to={m.route}
              style={({ isActive }) => {
                return { color: isActive ? "var(--main-color)" : "#FFF" };
              }}
            >
              {m.title}
            </NavLink>
          </li>
        );
      });
  };
  return (
    <div className="Menu">
      <ul>
        {view()}
        {Render()}
      </ul>
    </div>
  );
}
