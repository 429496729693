import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../../assets/css/Style.css";

export default function LM({
  content,
  showPopUp,
  setShowPopUp,
  setIsActive,
  session,
  clear,
  menuAPI,
  language
}) {
  let navigate = useNavigate();
  let [show, setShow] = useState(false);

  // Render Static Menu
  let view = () => {
    return content
    .filter((m) => language === m.locale)
    .map((m, index) => {
      return (
        <Link
        to={m.linkTo}
        key={index}
        onClick={() => {
          setShow(!show);
        }}
      >
        {m.item}
      </Link>
      )
    })
  }

  // Render Menu From API 
  let Render = () => {
    return menuAPI
    .filter((m) => language === m.locale)
    .map((m) => {
      return (
        <Link
        to={m.route}
        key={m.id}
        onClick={() => {
          setShow(!show);
        }}
      >
        {m.title}
      </Link>
      )
    })
  }

  let handleClick = () => {
    setShow(!show);
  };

  let handlerToggle = (p) => {
    setShowPopUp(!showPopUp);
    setIsActive(p);
    setShow(!show);
  };
  let checkMenuLogout = () => {
    if (sessionStorage.getItem('userData')) {
      return (
        language === 'ar' ?
        <span onClick={clear}>تسجيل الخروج</span> 
        : <span onClick={clear}>Logout</span>
      )
    } else {
      return (
        <div className="for-login">
          {
            language === 'ar' ?
            (
              <>
                <span onClick={() => handlerToggle("Login")}>تسجيل دخول</span>
                <span onClick={() => handlerToggle("SignIn")}>أنشاء حساب</span>
              </>
            )
            :
            (
              <>
                <span onClick={() => handlerToggle("Login")}>Login</span>
                <span onClick={() => handlerToggle("SignIn")}>Sign In</span>
              </>
            )
          }
        </div>
      );
    }
  };
  return (
    <div className="LM">
      <div className="icon" onClick={() => handleClick()}>
        <span
          style={{
            transform: show ? "rotate(45deg) translate(5px,5px)" : "",
            backgroundColor: show ? "var(--main-color)" : null,
          }}
        />
        <span
          style={{
            transform: show ? "rotate(-45deg)" : "",
            backgroundColor: show ? "var(--main-color)" : null,
          }}
        />
        <span style={{ opacity: show ? "0" : "1" }} />
      </div>
      <div
        className="menu"
        style={{display: show ? 'flex' : 'none'}}
      >
        {view()}
        {Render()}
        {checkMenuLogout()}
        <span onClick={() => navigate('/search')}>{language === 'ar' ? 'بحث' : 'Search'}</span>
      </div>
    </div>
  );
}
