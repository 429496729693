import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Style.css";

// Icons

import SvgApps from "../../assets/icons/SvgApps";
import SvgLocation from "../../assets/icons/SvgLocation";

export default function DataFilter(props) {
  // Get API Data
  let dataAPI = props.dataAPI;

  let language = props.language;

  let navigate = useNavigate();

  // State For Filter
  let change = props.change;
  let typeOf = props.type;

  // Render Data Filtered

  let RenderPropertyItem = (i, id) => {
    return i
      .filter((e) => language === e.locale)
      .filter((e) => {
        return change.governorate.toLowerCase() === ""
          ? e
          : e.governorates_Propert.governorate_name
              .toLowerCase()
              .includes(change.governorate) ||
              e.location.toLowerCase().includes(change.governorate);
      })
      .filter((e) => {
        return change.categorie === ""
          ? e
          : e.categories_Property.name.includes(change.categorie);
      })
      .map((v) => {
        if (typeOf === v.slug) {
          return (
            <div className="card" key={v.id}>
              <div className="img">
                <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${v.img}`} />
              </div>
              <div className="content">
                <div className="txt">
                  <span>{v.type_Property.name}</span>
                  <h3>{v.title}</h3>
                  <p>{v.dice}</p>
                </div>
                <div className="details">
                  <div className="txt">
                    <div className="location">
                      <h4>{language === 'ar' ? 'الموقع' : 'location'}</h4>
                      <div>
                        <SvgLocation />
                        <p>{`${v.governorates_Propert.governorate_name} - ${v.location}`}</p>
                      </div>
                    </div>
                    <div className="space">
                      <h4>{language === 'ar' ? 'المساحة' : 'space'}</h4>
                      <div>
                        <SvgApps />
                        <p>{v.space}</p>
                      </div>
                    </div>
                  </div>
                  <button onClick={() => navigate(`../${v.slug}/${id}`)}>
                    {language === 'ar' ? 'التفاصيل' : 'details'}
                  </button>
                </div>
              </div>
            </div>
          );
        }
      });
  };

  let RenderProperty = () => {
    return dataAPI.map((i) => {
      return RenderPropertyItem(i.Property_items, i.id);
    });
  };

  return (
    <div className="DataFilter NA">
      <div className="box">{RenderProperty()}</div>
    </div>
  );
}
