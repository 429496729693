import React from "react";
import "../../../assets/css/Style.css";

export default function AU(props) {
  let language = props.languageValue;

  // Get API
  let aboutAPI = props.aboutAPI;

  let Render = () => {
    return aboutAPI
      .filter((a) => language === 'ar' ? a.id_locale === 1 : a.id_locale === 2)
      .map((a) => {
        return (
          <div key={a.id}>
            <h1>{a.title}</h1>
            <div className="box">
              <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${a.img}`} alt="about-us" />
              <div className="txt">
                <p>{a.details}</p>
              </div>
            </div>
          </div>
        );
      });
  };

  return (
    <div className="AU">
      {Render()}
    </div>
  );
}
