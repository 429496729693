import "./App.css";
import React, {useEffect, useState} from "react";
import { Routes, Route } from "react-router-dom";
// Axios
import axios from "axios";
// Components
import Home from "./real-estate Container/Home/Home";
import Rent from "./real-estate Container/Rent/Rent";
import SaleDetails from "./real-estate Container/Sale/SaleDetails";
import SharedLayout from "./real-estate Container/SharedLayout";
import RentDetails from "./real-estate Container/Rent/RentDetails";
import Sale from "./real-estate Container/Sale/Sale";
import SearchPage from "./real-estate Container/Search_Page/SearchPage";
import REL from "./real-estate Container/Loading-Components/Real-Estate-Loading";
import PP from "./real-estate Container/Privacy-Policy/PP";
import DP from "./real-estate Container/Dynamic-Pages/DP";
import EP from "./real-estate Container/Error-Page/EP";

function App() {
  // State For Language
  let [languageValue, setLanguageValue] = useState('ar');

  // Fetch Data from APIs
  let [dataAPI, setDataAPI] = useState();
  let api = "https://www.properties-api.mediamaxtv.com/api/v1/proprty";
  useEffect(() => {
    let getData = async () => {
      await axios
        .get(api)
        .catch(e => console.log(e))
        .then((res) => {
          if (res) {
            setDataAPI(res.data.data)
          }else {
            console.log('load')
          }
        })
    };
    getData();
  }, []);

  // Fetch About Data from APIs
  let [aboutAPI, setAboutAPI] = useState();
  useEffect(() => {
    let getData = async () => {
      await axios
        .get("https://www.properties-api.mediamaxtv.com/api/v1/proprty/abouts")
        .then((res) => {
          if (res) {
            setAboutAPI(res.data.data)
          }else {
            console.log('load')
          }
        })
        .catch(e => console.log(e))
    };
    getData();
  }, []);

  // Fetch Reviews Data from APIs
  let [reviewsAPI, setReviewsAPI] = useState();
  useEffect(() => {
    let getData = async () => {
      await axios
        .get("https://www.properties-api.mediamaxtv.com/api/v1/proprty/reviews")
        .then((res) => {
          if (res) {
            setReviewsAPI(res.data.data)
          }else {
            console.log('load')
          }
        })
        .catch(e => console.log(e))
    };
    getData();
  }, []);

  // Fetch Pages Data from APIs
  let [pagesAPI, setPagesAPI] = useState();
  useEffect(() => {
    let getData = async () => {
      await axios
        .get("https://www.properties-api.mediamaxtv.com/api/v1/menu-pages")
        .then((res) => {
          if (res) {
            setPagesAPI(res.data.data)
          }else {
            console.log('load')
          }
        })
        .catch(e => console.log(e))
    };
    getData();
  }, []);

  // Fetch Contact Us Data from APIs
  let [contactAPI, setContactAPI] = useState();
  useEffect(() => {
    let getData = async () => {
      await axios
        .get("https://www.properties-api.mediamaxtv.com/api/v1/proprty/contact")
        .then((res) => {
          if (res) {
            setContactAPI(res.data.data)
          }else {
            console.log('load')
          }
        })
        .catch(e => console.log(e))
    };
    getData();
  }, []);

  // Render Route Pages
  let Render = () => {
    return pagesAPI
    .filter((p) => languageValue === p.locale)
    .map((p) => {
      return(
        <>
          <Route key={p.id} path={p.route} element={<DP content={p.page_tr} language={languageValue} />} />
        </>
      )
    })
  }
  
  return (
    <div className="App">
      {dataAPI && aboutAPI && reviewsAPI && pagesAPI && contactAPI ? (
        <>
      <Routes>
        <Route path="/" element={<SharedLayout setLanguageValue={setLanguageValue} language={languageValue} pagesAPI={pagesAPI} contactAPI={contactAPI} />}>
          <Route index element={<Home dataAPI={dataAPI} languageValue={languageValue} aboutAPI={aboutAPI} reviewsAPI={reviewsAPI} />} />
          <Route path="Sale" element={<Sale dataAPI={dataAPI} languageValue={languageValue} />} />
          <Route path="Sale/:itemId" element={<SaleDetails dataAPI={dataAPI} languageValue={languageValue} />} />
          <Route path="Rent" element={<Rent dataAPI={dataAPI} languageValue={languageValue} />} />
          <Route path="Rent/:itemId" element={<RentDetails dataAPI={dataAPI} languageValue={languageValue} />} />
          <Route path="search" element={<SearchPage dataAPI={dataAPI} languageValue={languageValue} />} />
          {/* <Route path="privacy-policy" element={<PP />} /> */}
          {Render()}
        </Route>
        {/* Error Page */}
        <Route path="*" element={<EP />} />
      </Routes>
        </>
      ) : (<REL />)}
    </div>
  );
}

export default App;
