import React, { useState } from "react";
import "../../assets/css/Style.css";
// Data
import SearchData from "./SearchData";

export default function FormSearch(props) {
  let language = props.language;

  // State For Filter
  let change = props.change;
  let setChange = props.setChange;
  let typeOf = props.type;
  let setTypeOf = props.setType;

  let types = SearchData.type;
  let categorie = SearchData.categories;
  // Render
  let renderTypes = () => {
    return types
      .filter((e) => language === e.locale)
      .map((item, index) => {
        return (
          <div
            key={index}
            className={item.class}
            onClick={() => setTypeOf(item.value)}
            style={{
              backgroundColor:
                typeOf === item.value ? "var(--main-color)" : null,
              color: typeOf === item.value ? "#FFF" : null,
            }}
          >
            {item.text}
          </div>
        );
      });
  };
  let renderSelects = () => {
    return categorie
      .filter((e) => language === e.locale)
      .map((item, index) => {
        return (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        );
      });
  };
  return (
    <div className="SFR FormSearch">
      <h1>
        City : {change.governorate} && Type : {change.categorie}
      </h1>
      <div className="type">{renderTypes()}</div>
      <form>
        <div>
          <input
            type="text"
            placeholder={language === 'ar' ? "البحث في محافظة او مدينة" : 'Search in a city'}
            onChange={(e) =>
              setChange({ ...change, governorate: e.target.value })
            }
            value={change.governorate}
            style={{direction: language === 'ar' ? 'rtl' : 'ltr'}}
          />
          <select
            onChange={(e) =>
              setChange({ ...change, categorie: e.target.value })
            }
            style={{direction: language === 'ar' ? 'rtl' : 'ltr'}}
            value={change.categorie}
          >
            {renderSelects()}
          </select>
          {/* <input type="number" placeholder={language === 'ar' ? "السعر" : 'price'} style={{direction: language === 'ar' ? 'rtl' : 'ltr'}} /> */}
        </div>
      </form>
    </div>
  );
}
