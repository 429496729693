let cards = [
  {
    id: 1,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "أيجار",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Rent",
  },
  {
    id: 2,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "أيجار",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Rent",
  },
  {
    id: 3,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "أيجار",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Rent",
  },
  {
    id: 4,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "للبيع",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Sale",
  },
  {
    id: 5,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "للبيع",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Sale",
  },
  {
    id: 6,
    title: "شقة سكنية 200م للبيع بمدينة الفيوم الجديدة الفيوم",
    type: "للبيع",
    details: "ملزم عقد أيجار",
    location: "صنعاء - حده - جوار المعلم",
    space: "5",
    img: require("../../assets/imgs/rmb-coins-stacked-front-housing-model-house-prices-house-buying-real-estate-mortgage-concept.jpg"),
    value: "Sale",
  },
];

export default cards;
