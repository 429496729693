import React from "react";
import "../../assets/css/Style.css";
import { useParams } from "react-router-dom";
import SvgLocation from "../../assets/icons/SvgLocation";
import SvgApps from "../../assets/icons/SvgApps";

let SaleDetails = (props) => {
  let dataAPI = props.dataAPI;

  let language = props.languageValue;
  // Render
  let { itemId } = useParams();

  let RenderImagesProperty = (i) => {
    return i.map((i) => {
      return (
        <img
          src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.url}`}
          alt={i.details}
          key={i.id}
        />
      );
    });
  };

  let RenderAmenitiesProperty = (i) => {
    return i.map((i) => {
      return (
        <div key={i.id}>
          <img
            src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.icon}`}
          />
          <span>{i.name}</span>
        </div>
      );
    });
  };

  let RenderFacilitieProperty = (i) => {
    return i.map((i) => {
      return (
        <div key={i.id}>
          <img
            src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.icon}`}
          />
          <span>{`${i.name} : ${i.facilities_number}`}</span>
        </div>
      );
    });
  };

  let RenderProperty = () => {
    if (dataAPI) {
      let card = dataAPI.find((i) => i.id.toString() === itemId.toString());
      let { id, Property_items } = card;
      return Property_items.filter((i) => language === i.locale).map((i) => {
        return (
          <div
            className="box"
            key={id}
            style={{ direction: language === "ar" ? "rtl" : "ltr" }}
          >
            <div className="imgs">
              <div className="big-imgs">
                <img
                  src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.img}`}
                  alt={i.id}
                />
              </div>
              <div className="little-imgs">
                {RenderImagesProperty(i.image_Property)}
              </div>
            </div>
            <div className="title">
              <h2>{i.title}</h2>
            </div>
            <div className="types">
              <div>{i.type_Property.name}</div>
              <div>{i.categories_Property.name}</div>
            </div>
            <div className="txt">
              <div className="box-content">
                <div className="location-and-space">
                  <div>
                    <h3>{language === "ar" ? "الموقع" : "location"}</h3>
                    <div className="details">
                      <SvgLocation />
                      <span>{`${i.governorates_Propert.governorate_name} - ${i.location}`}</span>
                    </div>
                  </div>
                  <div>
                    <h3>{language === "ar" ? "المساحة" : "space"}</h3>
                    <div className="details">
                      <SvgApps />
                      <span>{i.space}</span>
                    </div>
                  </div>
                </div>
                <div className="details-of-item">
                  <h3>{language === "ar" ? "الوصف" : "details"}</h3>
                  <div>{i.dice}</div>
                </div>
                <div className="the-facilities ameniti-property">
                  <h3>
                    {language === "ar"
                      ? "مايميز العقار"
                      : "what'is the special of this real estate"}
                  </h3>
                  <div className="facilities-box ameniti-box">
                    {RenderAmenitiesProperty(i.Amenitie_Property)}
                  </div>
                </div>
              </div>
              <div className="box-content">
                <div className="the-facilities">
                  <h3>{language === "ar" ? "المرافق" : "facilities"}</h3>
                  <div className="facilities-box">
                    {RenderFacilitieProperty(i.Facilitie_Property)}
                  </div>
                </div>
                <div className="location-in-map">
                  <h3>
                    {language === "ar"
                      ? "الموقع في الخريطة"
                      : "location in the map"}
                  </h3>
                  <img src={require("../../assets/imgs/map.jpeg")} />
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      console.log("loding in Sale");
    }
  };
  return (
    <div className="Sale-Details Details">
      <div className="container">{RenderProperty()}</div>
    </div>
  );
};

export default SaleDetails;
