import React, { useState } from "react";
import "../../assets/css/Style.css";
// Data
import cards from "../Sale/data";
// Components
import DataFilter from "./DataFilter";
import FormSearch from "./FormSearch";

export default function SearchPage(props) {
  // State For Filter
  let [type, setType] = useState("Sale");

  let [changeTxt, setChangeTxt] = useState({governorate: '', categorie: '', price: ''})
  
  return (
    <div className="SFR SearchPage">
      <div className="container">
        <div className="form">
          <FormSearch change={changeTxt} setChange={setChangeTxt} type={type} setType={setType} language={props.languageValue} />
        </div>
        <div className="data">
          <DataFilter content={cards} change={changeTxt} type={type} dataAPI={props.dataAPI} language={props.languageValue} />
        </div>
      </div>
    </div>
  );
}
