let SearchData = {
  title: "البحث عن عقار",
  type: [
    { text: "للبيع", class: "for sale", value: "Sale", locale: 'ar' },
    { text: "For Sale", class: "for sale", value: "Sale", locale: 'en' },
    { text: "للأيجار", class: "for rent", value: "Rent", locale: 'ar' },
    { text: "For Rent", class: "for rent", value: "Rent", locale: 'en' },
  ],
  categories: [
    { name: "شقة", locale: 'ar'},
    { name: "بيت", locale: 'ar'},
    { name: "عمارة", locale: 'ar'},
    { name: "house", locale: 'en'},
    { name: "apartment", locale: 'en'},
    { name: "building", locale: 'en'},
  ],
};
export default SearchData;