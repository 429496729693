import React from "react";
import '../../../assets/css/Style.css';


export default function SM(props){
    // Get Contact Us Data From API
    let contactAPI = props.contactAPI;

    let content = props.content;

    // Render
    let box = contactAPI.map((item) => {
        let Icon = item.src;
        return(
            // <div className={item.name} key={item.id}><Icon /></div>
            <a href={item.link}>
                <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${item.icon}`} alt={item.name} key={item.id} />
            </a>
        )
    })
    return(
        <div className="SM">{box}</div>
    )
}