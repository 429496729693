import React from "react";
import "../../assets/css/Style.css";
import SvgError from "./SvgError";
import { useNavigate } from "react-router-dom";

export default function EP(){
    let navigate = useNavigate();
    return(
        <div className="error-page">
            <div className="container">
                <SvgError />
                <div className="content">
                    <h1>Oop!</h1>
                    <div className="error-txt">
                        Page Not Found
                    </div>
                    <button onClick={() => navigate('/')}>back to Home</button>
                </div>
            </div>
        </div>
    )
}