import React, {useState} from "react";
import "../../assets/css/Style.css";
import SvgX from "../../assets/icons/SvgX";

export default function Login({ content, show, setShow, isActive, setIsActive, check, user, language  }) {
  let [checkLogin, setCheckLogin] = useState({phone: '', pass: ''});
  
  let handlerToggle = () => {
    setShow(!show);
    setIsActive('');
  }
  let handlerSubmit = e => {
    e.preventDefault();
    check(checkLogin)
  }
  return (
    <div className="Login" style={{top: isActive === 'Login' ? '50%' : '-50%'}}>
        <h1>{language === 'ar' ? 'تسجيل الدخول' : 'Login'}</h1>
        <form onSubmit={handlerSubmit}>
          <div>
            <input type='phone' placeholder={language === 'ar' ? "رقم الهاتف" : 'Phone Number'} required onChange={e => setCheckLogin({...checkLogin, phone: e.target.value})} value={checkLogin.phone} />
            <input type='password' placeholder={language === 'ar' ? "كلمة السر" : 'Password'} required onChange={e => setCheckLogin({...checkLogin, pass: e.target.value})} value={checkLogin.pass} />
          </div>
          <div className="error">{user ? null : `${language === 'ar' ? 'تأكد من رقم الهاتف او كلمة المرور' : 'Check Your Phone Number Or Password'}`}</div>
          <button type="submit">{language === 'ar' ? 'تسجيل' : 'Login'}</button>
        </form>
        <div className="acount">
            <div>{language === 'ar' ? 'ليس لديك حساب ؟' : "Don't You Have An Account ?"}</div>
            <a href="#" onClick={() => setIsActive('SignIn')}>{language === 'ar' ? 'انشاء حساب' : 'Go Sign In'}</a>
        </div>
        <div className="X" onClick={() => handlerToggle()}>
          <SvgX />
        </div>
    </div>
  );
}
