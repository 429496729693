import React from "react";
import "../../assets/css/Style.css";
import { useNavigate } from "react-router-dom";
// Icons
import SvgApps from "../../assets/icons/SvgApps";
import SvgLocation from "../../assets/icons/SvgLocation";

export default function Rent(props) {
  let navigate = useNavigate();

  let language = props.languageValue;

  // Get API Data
  let dataAPI = props.dataAPI;

  // Render

  let RenderPropertyItem = (i, id) => {
    return i
      .filter((i) => language === i.locale && "Rent" === i.slug)
      .map((i) => {
        return (
          <div className="card" key={id}>
            <div className="img">
              <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.img}`}  />
            </div>
            <div className="content">
              <div className="txt">
                <span>{i.type_Property.name}</span>
                <h3>{i.title}</h3>
                <p>{i.dice}</p>
              </div>
              <div className="details">
                <div className="txt">
                  <div className="location">
                    <h4>الموقع</h4>
                    <div>
                      <SvgLocation />
                      <p>{i.location}</p>
                    </div>
                  </div>
                  <div className="space">
                    <h4>المساحة</h4>
                    <div>
                      <SvgApps />
                      <p>{i.space}</p>
                    </div>
                  </div>
                </div>
                <button onClick={() => navigate(`/${i.slug}/${id}`)}>
                  التفاصيل
                </button>
              </div>
            </div>
          </div>
        );
      });
  };

  let RenderProperty = () => {
    return dataAPI.map((i) => {
      return RenderPropertyItem(i.Property_items, i.id);
    });
  };

  return (
    <div className="Rent Show-Pages">
      <div className="container">
        <div className="box">{RenderProperty()}</div>
      </div>
    </div>
  );
}
