import React from "react";

const SvgTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M1.565 3.052C3.575 5.626 7.172 8.054 12 8.315c-.266-1.117-.292-4.15 1.813-5.41C14.755 2.34 15.646 2 16.696 2c1.32 0 2.402.456 3.554 1.566.938-.138 2.689-.897 3.104-1.195-.27 1.046-1.353 2.39-2.135 2.71.719-.003 2.297-.436 2.781-.691-.671 1.088-1.843 2.13-2.484 2.563.742 6.704-5.182 15.039-13.69 15.047-2.43.002-5.07-.518-7.826-2.105 2.719.32 5.632-.568 7.304-2.106-2.109-.048-4.245-1.482-5.217-3.684 1.057.332 2.031.5 2.609 0C3.71 13.994.522 12.23.522 8.819c.733.611 1.788 1.244 2.608 1.075C1.788 9.093-.163 6.04 1.565 3.052z"
      fill="#1BA8E1"
    />
    <path
      d="M12.043 8.57c-.017-.07-.029-.17-.043-.255-4.806-.273-8.432-2.697-10.435-5.263a4.881 4.881 0 0 0-.673 2.466c.04-.719.241-1.47.673-2.216 2.01 2.574 5.65 5.007 10.478 5.268zM13.813 3.154c.942-.564 1.833-.904 2.883-.904 1.32 0 2.402.456 3.554 1.566.879-.129 2.458-.8 2.997-1.13.04-.106.08-.213.107-.315-.415.298-2.167 1.057-3.104 1.195C19.098 2.456 18.015 2 16.696 2c-1.05 0-1.941.34-2.883.904-1.616.968-1.973 2.975-1.932 4.363.009-1.37.41-3.202 1.932-4.113z"
      fill="#FFF"
      opacity={0.2}
    />
    <path
      d="M21.504 6.711c.009.08.005.162.012.242.643-.437 1.817-1.481 2.484-2.563-.063.033-.146.07-.241.107-.684.953-1.685 1.83-2.255 2.214zM.537 8.84l-.015-.013c0 3.124 2.69 4.931 4.174 5.278.107-.047.21-.104.3-.182-.96-.108-4.299-1.846-4.459-5.083zM7.826 21.75c-2.272.002-4.73-.465-7.292-1.822-.178-.01-.357-.013-.534-.033C2.756 21.482 5.396 22.002 7.826 22c8.037-.008 13.76-7.444 13.745-13.92-.13 6.416-5.811 13.662-13.745 13.67z"
      fill="#010101"
      opacity={0.1}
    />
    <path
      d="m2.243 14.16-.156-.046c.929 2.105 2.942 3.58 5.217 3.675.091-.074.193-.143.277-.221-2.01-.046-4.31-1.38-5.338-3.408z"
      fill="#010101"
      opacity={0.1}
    />
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="a"
      x1={0.244}
      x2={19.689}
      y1={5.836}
      y2={14.904}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#fff",
          stopOpacity: 0.2,
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#fff",
          stopOpacity: 0,
        }}
      />
    </linearGradient>
    <path
      d="M1.565 3.052C3.575 5.626 7.172 8.054 12 8.315c-.266-1.117-.292-4.15 1.813-5.41C14.755 2.34 15.646 2 16.696 2c1.32 0 2.402.456 3.554 1.566.938-.138 2.689-.897 3.104-1.195-.27 1.046-1.353 2.39-2.135 2.71.719-.003 2.297-.436 2.781-.691-.671 1.088-1.843 2.13-2.484 2.563.742 6.704-5.182 15.039-13.69 15.047-2.43.002-5.07-.518-7.826-2.105 2.719.32 5.632-.568 7.304-2.106-2.109-.048-4.245-1.482-5.217-3.684 1.057.332 2.031.5 2.609 0C3.71 13.994.522 12.23.522 8.819c.733.611 1.788 1.244 2.608 1.075C1.788 9.093-.163 6.04 1.565 3.052z"
      fill="url(#a)"
    />
  </svg>
);

export default SvgTwitter;
