import React from "react";
import "../../../assets/css/Style.css";
import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

// import required modules
import { Pagination, Navigation } from "swiper";

// Icons
import SvgApps from "../../../assets/icons/SvgApps";
import SvgLocation from "../../../assets/icons/SvgLocation";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SvgNext = (props) => (
  <svg
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM6.354 13.354l-.707-.707L10.293 8 5.646 3.354l.707-.707L11.707 8l-5.353 5.354z" />
  </svg>
);

const SvgBack = (props) => (
  <svg
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm2.354 12.646-.707.707L4.293 8l5.354-5.354.707.707L5.707 8l4.647 4.646z" />
  </svg>
);
export default function SOS(props) {
  let navigate = useNavigate();

  let dataAPI = props.data;

  let languageValue= props.languageValue;

  // ---------- Render Swiper Items ----------

  let RenderPropertyItem = (i, id) => {
    return i
      .filter((i) => languageValue === i.locale)
      .map((i) => {
        return (
          <div className="card" key={id}>
            <div className="img">
            {/* <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.img}`} alt={i.id} /> */}
            <LazyLoadImage src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.img}`} alt={i.id} />
            </div>
            <div className="content">
              <div className="txt">
                <span>{i.type_Property.name}</span>
                <h3>{i.title}</h3>
                <p>{i.dice}</p>
              </div>
              <div className="details">
                <div className="txt">
                  <div className="location">
                    <h4>{languageValue === 'ar' ? 'الموقع' : 'location'}</h4>
                    <div>
                      <SvgLocation />
                      <p>{`${i.governorates_Propert.governorate_name} - ${i.location}`}</p>
                    </div>
                  </div>
                  <div className="space">
                    <h4>{languageValue === 'ar' ? 'المساحة' : 'space'}</h4>
                    <div>
                      <SvgApps />
                      <p>{i.space}</p>
                    </div>
                  </div>
                </div>
                <button onClick={() => navigate(`/${i.slug}/${id}`)}>
                  {languageValue === 'ar' ? 'التفاصيل' : 'details'}
                </button>
              </div>
            </div>
          </div>
        );
      });
  };

  let RenderProperty = () => {
    if (dataAPI) {
      return dataAPI.map((item) => {
        return (
          <SwiperSlide key={item.id}>
            {RenderPropertyItem(item.Property_items, item.id)}
          </SwiperSlide>
        );
      });
    } else {
      console.log("load");
    }
  };

  return (
    <div className="SOS">
      <h1>{props.content.title}</h1>
      <div>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            500: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            767: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
          }}
          spaceBetween={10}
          slidesPerGroup={1}
          loop={false}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          style={{ paddingBottom: "10px" }}
        >
          {RenderProperty()}
          <div className="move">
            <SvgBack className="prev" />
            <Paginations className="swiper-pagination" />
            <SvgNext className="next" />
          </div>
        </Swiper>
      </div>
    </div>
  );
}

let Paginations = (props) => {
  return <div {...props}></div>;
};
