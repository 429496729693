import React from "react";
import "../../../assets/css/Style.css";
import { useNavigate } from "react-router-dom";
// Icons
import SvgApps from "../../../assets/icons/SvgApps";
import SvgLocation from "../../../assets/icons/SvgLocation";

export default function NA(props) {
  let navigate = useNavigate();

  let language = props.languageValue;

  // Get API Data
  let dataAPI = props.dataAPI;

  let RenderPropertyItem = (i, id) => {
    return i
      .filter((i) => language === i.locale)
      .map((i) => {
        return (
          <div className="card" key={id}>
            <div className="img">
              <img src={`https://www.properties-api.mediamaxtv.com/storage/proprty/image/${i.img}`} />
            </div>
            <div className="content">
              <div className="txt">
                <span>{i.type_Property.name}</span>
                <h3>{i.title}</h3>
                <p>{i.dice}</p>
              </div>
              <div className="details">
                <div className="txt">
                  <div className="location">
                    <h4>{language === 'ar' ? 'الموقع' : 'location'}</h4>
                    <div>
                      <SvgLocation />
                      <p>{i.location}</p>
                    </div>
                  </div>
                  <div className="space">
                    <h4>{language === 'ar' ? 'المساحة' : 'space'}</h4>
                    <div>
                      <SvgApps />
                      <p>{i.space}</p>
                    </div>
                  </div>
                </div>
                <button onClick={() => navigate(`/${i.slug}/${id}`)}>{language === 'ar' ? 'التفاصيل' : 'details'}</button>
              </div>
            </div>
          </div>
        );
      });
  };
  // To Get Last Items Use dataAPI.slice(-6)

  let RenderProperty = () => {
    return dataAPI
    .slice(-6)
    .map((i) => {
      return RenderPropertyItem(i.Property_items, i.id);
    });
  };

  return (
    <div className="NA">
      <h1>{props.content.title}</h1>
      <div className="box">{RenderProperty()}</div>
    </div>
  );
}
